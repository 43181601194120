/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, ContactForm } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <Column className="pb--60 pt--60" name={"uvod-2"} style={{"backgroundColor":"rgba(50,105,245,1)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s4 el--1 pb--20 pl--25 pr--25 pt--20" anim={"2"} animS={"4"} style={{"maxWidth":1280}} columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Title className="title-box" content={"<span style=\"color: rgb(255, 255, 255);\">Vaší zpětné vazby si vážíme!</span><br>"}>
              </Title>

              <Text className="text-box fs--22" style={{"maxWidth":1056}} content={"Naše služby se pro vás snažíme neustále zlepšovat a jsme moc rádi, že jste s námi spokojení. Pokud přeci jen potřebujete funkci, kterou zatím nemáme, tak dejte nám vědět!&nbsp;&nbsp;"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"hesyffbp9ra"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style1" style={{"maxWidth":740}} action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":false,"placeholder":"Zadejte celé jméno"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail"},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Popište o co máte zájem"},{"name":"Odeslat","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"paticka"} style={{"paddingTop":12,"paddingBottom":0,"backgroundColor":"rgba(50,105,245,1)"}}>
          
          <ColumnWrap className="column__flex el--1 pb--16 pl--25 pr--25 pt--10" style={{"maxWidth":1280}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Text className="text-box text-box--center" content={"Děkujeme za využívání našich služeb."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}